import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";

// material
import {
    TextField,
    Box,
    Grid,
    Container,
    Card,
    CardContent,
    Button
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// app
import BaseSnackbar from "src/components/BaseSnackbar";
import { useTranslation } from "react-i18next";
import { UserService } from "src/api/services";
import Logo from "../../Logo";
import { useStore } from "src/store/Store";
import BaseCard from "../../BaseCard";
import {useTheme} from "@mui/styles";
// ----------------------------------------------------------------------

export default function ForgotPassword() {
    const { t } = useTranslation();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const [width, setWidth] = useState(window.innerWidth);
    const [store, dispatch] = useStore();
    const [logo, setLogo] = useState("");


    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(() => {
        if (store.logo) {
            setLogo(store.logo);
        }
    }, [store.manualUpdateTriggeredForPalette, store.logo]);

    const isMobile = width <= 768;

    const RegisterSchema = Yup.object().shape({
        email: Yup.string()
            .email(t("form.__i18n_ally_root__.validation.email")).max(254, t("email-max-length"))
            .required(t("email-is-required")),
    });
    const getFormData = (values) => {
        return {
            email: values.email,
        };
    };

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: RegisterSchema,
        onSubmit: (values) => {
            const payload = getFormData(values);
            UserService.ResetTeamMemberPassword(payload)
                .then((response) => {
                    if (response.data != null && response.data.meta.code === 200) {
                        setMessage(response.data.meta.msg);
                        setOpenSnackbar(true);
                        formik.setSubmitting(false);
                    } else {
                        throw {
                            message: "Password mail successfully sent",
                            response: response,
                        };
                    }
                })
                .catch((err) => {
                    setMessage(t("Something went wrong"));
                    setOpenSnackbar(true);
                    formik.setSubmitting(false);
                });
        },
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;


    const openRegisterForm = () => {
        navigate("/register");
    };
    const openLoginForm = () => {
        navigate("/login");
    };
    const theme = useTheme();

    return (
        <>
            <BaseSnackbar
                open={openSnackbar}
                message={message}
                setOpen={setOpenSnackbar}
            />
            <Container maxWidth="xs"
                       sx={ isMobile ? {
                           height: "20vh",
                           position: "relative",
                           top: '10vh',
                           left: 0,
                       } : {   position:"absolute", left: "5%", bottom: "12vh" }}
            >
                <Grid
                    component={Card}
                >

                    <BaseCard>
                    <CardContent sx={{
                            marginX:1
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingY: 3,
                            }}
                        >
                            <Logo sx={{ width:200}} src={logo} /></Box>

                        <Grid item mb={1}>
                            <FormikProvider value={formik}>
                                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                    <TextField
                                        fullWidth
                                        autoComplete="email"
                                        type="email"
                                        label={t("email-address")}
                                        {...getFieldProps("email")}
                                        error={Boolean(touched.email && errors.email)}
                                        helperText={touched.email && errors.email}
                                        sx={{ marginBottom: 2 }}
                                    />

                                    <LoadingButton
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        loading={isSubmitting}
                                        sx={{'&:hover': {
                                                backgroundColor: theme.palette['--color-primary']
                                            },backgroundColor:theme.palette['--color-primary'], marginBottom:2}}

                                    >
                                        {"Reset Password"}
                                    </LoadingButton>
                                </Form>
                            </FormikProvider>

                            <Grid container justifyContent="space-between">
                                <Button
                                    type="submit"
                                    size="large"
                                    variant="outlined"
                                    onClick={openLoginForm}
                                    sx={{'&:hover': {
                                            borderColor: theme.palette['--color-primary'], color:theme.palette['--color-primary']
                                        },borderColor:theme.palette['--color-primary'], color:theme.palette['--color-primary'], marginBottom:2}}

                                >
                                    Sign in
                                </Button>
                                <Button
                                    type="submit"
                                    size="large"
                                    variant="outlined"
                                    onClick={openRegisterForm}
                                    sx={{'&:hover': {
                                            borderColor: theme.palette['--color-search'], color:theme.palette['--color-search']
                                        },borderColor:theme.palette['--color-search'], color:theme.palette['--color-search']}}
                                >
                                    Create new account
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                    </BaseCard>
                </Grid>
            </Container>
        </>
    );
}
