import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import {
  TextField,
  Box,
  Typography,
  Button,
  Grid,
  Container,
  Card,
  CardContent,
  InputAdornment
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PhoneIcon from '@mui/icons-material/Phone';
import LockIcon from '@mui/icons-material/Lock';
// app
import { useStore } from 'src/store/Store';
import { ProfileService, AccountService } from 'src/api/services';
import BaseSnackbar from 'src/components/BaseSnackbar';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/styles';
import Logo from 'src/components/Logo';
import BaseCard from 'src/components/BaseCard';

export default function AddPhoneForm() {
  const { t } = useTranslation();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState('');
  const [store, dispatch] = useStore();
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);
  const [logo, setLogo] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
    if (store.logo) {
      setLogo(store.logo);
    }
  }, [store.manualUpdateTriggeredForPalette, store.logo]);

  const isMobile = width <= 768;
  const theme = useTheme();

  const PhoneSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required(t('Phone number is required'))
      .matches(
        /^\+?[0-9]{10,30}$/,
        t('Please enter a valid phone number (Eg. +44xxxxxxxxxx)')
      )
  });

  const VerificationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required(t('Phone number is required'))
      .matches(
        /^\+?[0-9]{10,30}$/,
        t('Please enter a valid phone number (Eg. +44xxxxxxxxxx)')
      ),
    verificationCode: Yup.string()
      .required(t('Verification code is required'))
      .length(6, t('Verification code must be 6 digits'))
      .matches(/^[0-9]+$/, t('Verification code must contain only numbers'))
  });

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      verificationCode: ''
    },
    validationSchema: codeSent ? VerificationSchema : PhoneSchema,
    onSubmit: (values, { setSubmitting }) => {
      if (!codeSent) {
        setIsVerifying(true);
        AccountService.requestPhoneVerification({ telephone: values.phoneNumber })
          .then((response) => {
            if (response.status !== 200) {
              throw new Error("Verification request failed");
            }
            if (response.data.meta.code !== 200) {
              throw new Error(response.data.meta.msg);
            }
            
            setCodeSent(true);
            setMessage(t('Verification code sent successfully. Please check your phone.'));
            setOpenSnackbar(true);
          })
          .catch((err) => {
            setMessage(err.message || t('Failed to send verification code. Please try again.'));
            setOpenSnackbar(true);
          })
          .finally(() => {
            setSubmitting(false);
            setIsVerifying(false);
          });
      } else {
        setIsVerifying(true);
        AccountService.verifyPhoneNumberUpdate({ 
          telephone: values.phoneNumber,
          verificationCode: values.verificationCode 
        })
          .then((response) => {
            if (response.status !== 200) {
              throw new Error("Verification failed");
            }
            if (response.data.meta.code !== 200) {
              throw new Error(response.data.meta.msg);
            }

            localStorage.removeItem('force_add_phone');

            let details = localStorage.getItem('detail');
            details = JSON.parse(details);
            details['telephone'] = values.phoneNumber;
            localStorage.setItem('detail', JSON.stringify(details));

            dispatch({
              type: "UPDATE_TELEPHONE",
              payload: {
                telephone: values.phoneNumber
              }
            });
            
            setMessage(t('Phone number verified and updated successfully.'));
            setOpenSnackbar(true);
            
            setTimeout(() => {
              navigate('/', { replace: true });
            }, 1500);
          })
          .catch((err) => {
            setMessage(err.message || t('Failed to verify phone number. Please check the code and try again.'));
            setOpenSnackbar(true);
          })
          .finally(() => {
            setSubmitting(false);
            setIsVerifying(false);
          });
      }
    }
  });

  const handleLogout = () => {
    const admin = store.admin;
    (async () => {
      await dispatch({ type: "LOGOUT" });
    })();
    navigate(admin ? '/admin/login' : '/login', { replace: true });
  }

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <BaseSnackbar open={openSnackbar} message={message} setOpen={setOpenSnackbar} />
      <Container maxWidth="xs"
        sx={isMobile ? {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh"
        } : { position: "absolute", left: "5%", bottom: "12vh" }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ height: '100%' }}
        >
          <Grid item xs={10} sm={7} md={5} lg={4} xl={4} sx={{ display: 'table', height: '100%' }}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              component={Card}
            >
              <BaseCard>
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Logo sx={{ width: 200 }} src={logo} />
                  </Box>

                  <Grid item mb={1}>
                    <Typography mb={1} sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: '24px', color: theme.palette['--main-text-color'] }}>
                      {t('Phone Number Update')}
                    </Typography>
                    
                    <Typography mb={3} sx={{ textAlign: 'center', fontSize: '14px', color: theme.palette['--main-text-color'] }}>
                      {codeSent 
                        ? t('Enter the 6-digit verification code sent to your phone number.') 
                        : t('For your security, please add or correct your phone number. This will be used for two-factor authentication. Example: +44xxxxxxxxxx')}
                    </Typography>
                    
                    <FormikProvider value={formik}>
                      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <TextField
                          fullWidth
                          label={t('Phone Number')}
                          placeholder="+xxxxxxxxxxxx"
                          {...getFieldProps('phoneNumber')}
                          error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                          helperText={touched.phoneNumber && errors.phoneNumber}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PhoneIcon />
                              </InputAdornment>
                            ),
                          }}
                          disabled={codeSent && isVerifying}
                          sx={{ mb: 3 }}
                        />
                        
                        {codeSent && (
                          <TextField
                            fullWidth
                            label={t('Verification Code')}
                            placeholder="123456"
                            {...getFieldProps('verificationCode')}
                            error={Boolean(touched.verificationCode && errors.verificationCode)}
                            helperText={touched.verificationCode && errors.verificationCode}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LockIcon />
                                </InputAdornment>
                              ),
                            }}
                            disabled={isVerifying}
                            sx={{ mb: 3 }}
                          />
                        )}
                        
                        <LoadingButton
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          loading={isSubmitting}
                          sx={{
                            '&:hover': {
                              backgroundColor: theme.palette['--color-primary']
                            }, 
                            backgroundColor: theme.palette['--color-primary'], 
                            marginBottom: 2
                          }}
                        >
                          {codeSent 
                            ? t('Verify Phone Number') 
                            : t('Send Verification Code to Phone')}
                        </LoadingButton>
                        <Button
                          fullWidth
                          size="large"
                          variant="outlined"
                          onClick={handleLogout}
                          sx={{ color: theme.palette['--color-primary'], borderColor: theme.palette['--color-primary'] }}
                        >
                          {t('Logout')}
                        </Button>
                      </Form>
                    </FormikProvider>
                
                  </Grid>
                </CardContent>
              </BaseCard>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}