import { useState } from 'react';
// material
import {
    Box,
    FormControl,
    Grid,
    Stack,
    Typography,
} from '@mui/material';
import { NumberService } from 'src/api/services';
import { useTranslation } from 'react-i18next';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import  {useStore} from "../../../../store/Store";
import BaseButton from 'src/components/buttons/BaseButton';
import BaseTextField from 'src/components/BaseTextField';
// ----------------------------------------------------------------------

export default function DeallocateNumberForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [filterStart, setFilterStart] = useState(dayjs(new Date()).startOf('day'));

    const [store, dispatch] = useStore();

    const deallocationApproval = store.portalSettings.services.access_management.deallocation_approval ?? false;

    const [customerNotes, setCustomerNotes] = useState('');

    const deallocateNumber = () => {
        setLoading(true);
        let payload = {}
        if(deallocationApproval){
            payload = {
                did_id: formData.did_id,
                scheduled_at:filterStart.format('YYYY-MM-DDTHH:mm:ss'),
                customer_notes: customerNotes ? customerNotes : ""
            };

        }else{
            payload = {
                did_id: formData.did_id,
                customer_notes: customerNotes ? customerNotes : ""
            };
        }

        NumberService.deallocateNumber(payload)
            .then((response) => {
                if (response.data.meta.msg === "Ok") {
                    setMessage('Deallocation request has been successfully submitted', {number: formData.number_value});
                    setSnackbarStatus(true);
                    setModalStatus(false);
                    setLoading(false);
                    successCallback();
                } else {
                    throw {message:"number could not be deallocated", response:response};
                }
            })
            .catch((err) => {
                if(err.response === undefined){
                    setMessage("Connection failed");
                }else{
                    setMessage(err.response.data.meta.msg);
                }
                setSnackbarStatus(true);
                setModalStatus(false);
                setLoading(false);
            });
    }

    return (
        <>
            <Stack spacing={1}>
                <Typography component="h6">
                {'You are about to deallocate the number. Are you sure?'}
                </Typography>
                <Typography component="subtitle1" variant="h6">
                    {formData.number_value}
                </Typography>

                {deallocationApproval && (  <Grid container justifyContent="left">

                    <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker onChange={(date)=> setFilterStart(date)} value={filterStart} label="Preferred Scheduled At" />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>)}

                <FormControl fullWidth>
                    <BaseTextField
                        value={customerNotes}
                        label={t('Customer Reference (Optional)')}
                        name="customer_notes"
                        margin="normal"
                        variant="outlined"
                        color="secondary"
                        onChange={(value) => {
                            if (value.length <= 64) {
                                setCustomerNotes(value);
                            }
                        }} 
                    />
                </FormControl>

                <Stack sx={{ display: "block" }} direction="row" spacing={2}>
                    <BaseButton
                        label={'Cancel'}
                        onClick={() => setModalStatus(false)} color={"--color-search"}
                    />
                    <BaseButton
                        label={t('deallocate')}
                        onClick={deallocateNumber}color={"--color-primary"}
                    />
                </Stack>
            </Stack>
        </>
    );
}
