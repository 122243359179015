import * as Yup from 'yup';
import { useState, useEffect, useRef } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import {
  TextField,
  Box,
  Typography,
  Button,
  styled,
  Checkbox
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// app
import { useStore } from 'src/store/Store';
import { ProfileService, SiteConfigService } from 'src/api/services';
import BaseSnackbar from 'src/components/BaseSnackbar';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------
import { Grid, Container, Card, CardContent } from '@mui/material';
import Logo from "../../Logo";
import BaseCard from "../../BaseCard";
import { useTheme } from '@mui/styles';
import { CheckBox } from '@mui/icons-material';

const DigitInput = styled(TextField)(({ theme }) => ({
  width: '46px',
  margin: '0 4px',
  '& .MuiOutlinedInput-input': {
    padding: '12px 8px',
    textAlign: 'center',
    fontSize: '18px'
  }
}));

export default function TwoFactorAuthForm() {
  const { t } = useTranslation();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState('');
  const [store, dispatch] = useStore();
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);
  const [logo, setLogo] = useState("");
  const [email, setEmail] = useState("");
  const [tempToken, setTempToken] = useState("");
  const [countdown, setCountdown] = useState(60);
  const [canResend, setCanResend] = useState(false);
  const [digits, setDigits] = useState(['', '', '', '', '', '']);
  const inputRefs = useRef([]);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
    if (store.logo) {
      setLogo(store.logo);
    }
  }, [store.manualUpdateTriggeredForPalette, store.logo]);

  useEffect(() => {
    const storedEmail = sessionStorage.getItem('email');
    const storedTempToken = sessionStorage.getItem('temp_token');
    
    //if (!storedEmail || !storedTempToken) {
      // If data is missing, redirect back to login
      //navigate('/login', { replace: true });
      //return;
    //}
    
    setEmail(storedEmail);
    setTempToken(storedTempToken);
  }, [navigate]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  useEffect(() => {
    if (countdown > 0 && !canResend) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0 && !canResend) {
      setCanResend(true);
    }
  }, [countdown, canResend]);

  const isMobile = width <= 768;
  const [isRemembered, setRemembered] = useState(false);

  const fetchSettings = async (loginResponse) => {
    const settingsResponse = await SiteConfigService.getStyle();
    const serviceStatuses = settingsResponse.data.data;

    dispatch({
      type: "SET_SERVICE_STATUSES",
      payload: {
        services: serviceStatuses
      }
    });
    dispatch({
      type: "LOGIN",
      payload: {
        token: loginResponse.data.data.access_token,
        expires_at: loginResponse.data.data.expires_at,
        refresh_token: loginResponse.data.data.refresh_token,
        roles: loginResponse.data.data.roles,
        meta: loginResponse.data.meta,
        detail: loginResponse.data.data.detail,
        admin: false,
      }
    });
    dispatch({
      type: "UPDATE_SELECTED_LANGUAGE",
      payload: {
        language: "en-US",
      }
    });

    sessionStorage.removeItem('email');
    sessionStorage.removeItem('temp_token');
    
    navigate('/', { replace: true });
  };

  const VerificationSchema = Yup.object().shape({
    code: Yup.string()
      .required(t('Verification code is required'))
      .matches(/^[0-9]+$/, t('Code must contain only numbers'))
      .length(6, t('Code must be 6 digits'))
  });

  const formik = useFormik({
    initialValues: {
      code: ''
    },
    validationSchema: VerificationSchema,
    onSubmit: (values, { setSubmitting }) => {
      const code = digits.join('');
      
      if (code.length !== 6 || !/^\d+$/.test(code)) {
        setMessage(t('Please enter a valid 6-digit code'));
        setOpenSnackbar(true);
        setSubmitting(false);
        return;
      }
      
      const payload = {
        code: code,
        temp_token: tempToken,
        email: email,
        remember_me: isRemembered
      };

      ProfileService.verify2FA(payload)
        .then((response) => {
          if (response.status !== 200) {
            throw new Error("Verification failed");
          }
          if (response.data.meta.code !== 200) {
            throw new Error(response.data.meta.msg);
          }
          if (response.data.data.tfa_remember_token !== null) {
            localStorage.setItem('tfa_remember_token', response.data.data.tfa_remember_token);
        }
          fetchSettings(response);
        })
        .catch((err) => {
          setMessage(err.message || t('Invalid verification code. Please try again.'));
          setOpenSnackbar(true);
          dispatch({ type: "LOGIN_FAIL" });
        })
        .finally(() => {
          setSubmitting(false);
        });
      
      setSubmitting(false);
    }
  });

  
  const { errors, touched, isSubmitting, handleSubmit, setFieldValue } = formik;

  const handleDigitChange = (index, value) => {
    if (!/^\d*$/.test(value)) return; 
    
    const newDigits = [...digits];
    newDigits[index] = value.substring(0, 1); 
    setDigits(newDigits);
    
    setFieldValue('code', newDigits.join(''));
    
    if (value && index < 5 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace') {
      if (digits[index] === '' && index > 0 && inputRefs.current[index - 1]) {
        const newDigits = [...digits];
        newDigits[index - 1] = '';
        setDigits(newDigits);
        inputRefs.current[index - 1].focus();
      } else {
        const newDigits = [...digits];
        newDigits[index] = '';
        setDigits(newDigits);
      }
    }
    
    if (e.key === 'ArrowLeft' && index > 0 && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1].focus();
    }
    
    if (e.key === 'ArrowRight' && index < 5 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };



  const handleResendCode = () => {
    setCountdown(60);
    setCanResend(false);
    
    const payload = {
      email: email,
      temp_token: tempToken
    };

    
    ProfileService.resend2FA(payload)
      .then((response) => {
        if (response.status === 200) {
          setMessage(t('Verification code resent successfully.'));
          setOpenSnackbar(true);
          sessionStorage.setItem('temp_token', response.data.data.temp_token);
        } else {
          throw new Error("Failed to resend code");
        }
      })
      .catch((err) => {
        setMessage(t('Failed to resend verification code. Please try again.'));
        setOpenSnackbar(true);
      });

    setMessage(t('Verification code resent successfully.'));
    setOpenSnackbar(true);
  };

  const handleBackToLogin = () => {
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('temp_token');
    navigate('/login');
  };

  const theme = useTheme();

  return (
    <>
      <BaseSnackbar open={openSnackbar} message={message} setOpen={setOpenSnackbar} />
      <Container maxWidth="xs"
        sx={isMobile ? {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh"
        } : { position: "absolute", left: "5%", bottom: "12vh" }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ height: '100%' }}
        >
          <Grid item xs={10} sm={7} md={5} lg={4} xl={4} sx={{ display: 'table', height: '100%' }}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              component={Card}
            >
              <BaseCard>
                <CardContent sx={{
                }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Logo sx={{ width: 200 }} src={logo} />
                  </Box>

                  <Grid item mb={1}>
                    <Typography mb={1} sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: '24px', color: theme.palette['--main-text-color'] }}>
                      Two-Factor Authentication
                    </Typography>
                    
                    <Typography mb={3} sx={{ textAlign: 'center', fontSize: '14px', color: theme.palette['--main-text-color'] }}>
                      A verification code has been sent to your phone. Please enter the code below.
                    </Typography>
                    
                    <FormikProvider value={formik}>
                      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Box 
                          sx={{ 
                            display: 'flex', 
                            justifyContent: 'center', 
                            mb: 3
                          }}
                        >
                          {digits.map((digit, index) => (
                            <DigitInput
                              key={index}
                              inputRef={el => inputRefs.current[index] = el}
                              variant="outlined"
                              value={digit}
                              onChange={(e) => handleDigitChange(index, e.target.value)}
                              onKeyDown={(e) => handleKeyDown(index, e)}
                              inputProps={{
                                maxLength: 1,
                                style: { textAlign: 'center' }
                              }}
                            />
                          ))}
                        </Box>
                        
                        {errors.code && touched.code && (
                          <Typography color="error" sx={{ textAlign: 'center', mb: 2, fontSize: '0.75rem' }}>
                            {errors.code}
                          </Typography>
                        )}

                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mb: 2
                          }}
                        >
                          <Checkbox
                            sx={{ padding: 0, color: theme.palette['--color-primary'] }}
                            checked={isRemembered}
                            onChange={(e) => setRemembered(e.target.checked)}

                          />
                          <Typography
                            sx={{ color: theme.palette['--main-text-color'] }}
                          >
                            Remember this device for 30 days
                          </Typography>
                        </Box>
                        
                        <LoadingButton
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          loading={isSubmitting} 
                          sx={{
                            '&:hover': {
                              backgroundColor: theme.palette['--color-primary']
                            }, 
                            backgroundColor: theme.palette['--color-primary'], 
                            marginBottom: 2
                          }}
                        >
                          {t('Verify')}
                        </LoadingButton>
                      </Form>
                    </FormikProvider>
                    
                    <Button
                      fullWidth
                      size="large"
                      variant="outlined"
                      disabled={!canResend}
                      onClick={handleResendCode}
                      sx={{
                        '&:hover': {
                          color: theme.palette['--color-search'], 
                          borderColor: theme.palette['--color-search']
                        }, 
                        marginBottom: 2, 
                        color: theme.palette['--color-search'], 
                        borderColor: theme.palette['--color-search']
                      }}
                    >
                      {canResend ? t('Resend Code') : `${t('Resend Code')} (${countdown}s)`}
                    </Button>
                    
                    <Button 
                      variant="text" 
                      fullWidth 
                      onClick={handleBackToLogin}
                      sx={{ color: theme.palette['--color-primary'] }}
                    >
                      {t('Back to Login')}
                    </Button>
                  </Grid>
                </CardContent>
              </BaseCard>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}