import api from "src/api/Api";
import urls from "src/api/Urls";



export const updateProfile = (payload) => {
    return api.post(urls.update_profile, payload);
}


export const requestPhoneVerification = (payload) => {
    return api.post(urls.request_phone_verification, payload);
}


export const verifyPhoneNumberUpdate = (payload) => {
    return api.post(urls.verify_phone_number, payload);
}
