// ----------------------------------------------------------------------
import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import {
    Grid,
    TextField,
    FormControl,
    Stack,
    FormGroup, Checkbox, FormControlLabel
} from '@mui/material';
import { TableFilterContainer } from 'src/components/table';
import {NumberService} from 'src/api/services';
import { useTranslation } from 'react-i18next';
import BaseButton from "../../../../components/buttons/BaseButton";
import BaseTextField from 'src/components/BaseTextField';


const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

const StyledCheckboxLabel = styled(FormControlLabel)(({ theme }) => ({
    color: theme.palette['--color-search']
}));


export default function AddSMSEndpointForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();

    const [openOrderNumberModal, setOrderNumberModalStatus] = useState(false);
    const [data, setData] = useState([]);

    const [name, setName] = useState("");
    const [endpoint, setEndpoint] = useState("");
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [body, setBody] = useState("");
    const [id, setId] = useState("");
    const [type, setType] = useState("");
    const [http_post_url, setHttpPostUrl] = useState("");

    const [isDefault, setIsDefault] = useState(false);


    const updateEndpoint = () => {
        if(name == ""){
            setMessage("Name required");
            setSnackbarStatus(true);
            return;
        }

        if(http_post_url == ""){
            setMessage("Endpoint required");
            setSnackbarStatus(true);
            return;
        }
        
        NumberService.addSMSEndpoint( {name:name, http_post_url:http_post_url, id_field:id, from_field:from, to_field:to, body_field:body, type_field:type, is_default: isDefault})
            .then((response) => {
                if (response.data.status == true) {
                    setMessage("SMS endpoint added");
                    setSnackbarStatus(true);
                    setModalStatus(false);
                    successCallback();
                } else {
                    throw response.data.meta.msg;
                }
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
    }

    useEffect(() => {

    }, []);


    return (
        <>
            <TableFilterContainer>
                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <BaseTextField
                            value={name}
                            label={t('name')}
                            name="name"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            onChange={setName}
                        />
                    </FormControl>
                </Grid>
                <br/>

                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <BaseTextField
                            value={http_post_url}
                            label={"HTTP Endpoint"}
                            name="address"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            onChange={setHttpPostUrl}
                        />
                    </FormControl>
                </Grid>
                <br/>

                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <BaseTextField
                            value={from}
                            label={"From Field"}
                            name="priority"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            onChange={setFrom}
                        />
                    </FormControl>
                </Grid>
                <br/>

                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <BaseTextField
                            value={to}
                            label={"To Field"}
                            name="weight"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            onChange={setTo}
                        />
                    </FormControl>
                </Grid>
                <br/>

                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <BaseTextField
                            value={body}
                            label={"Body Field"}
                            name="weight"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            onChange={setBody}
                        />
                    </FormControl>
                </Grid>
                <br/>

                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <BaseTextField
                            value={id}
                            label={"ID Field"}
                            name="weight"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            onChange={setId}
                        />
                    </FormControl>
                </Grid>
                <br/>

                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <BaseTextField
                            value={type}
                            label={"Type Field"}
                            name="weight"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            onChange={setType}
                        />
                    </FormControl>
                </Grid>
                <br/>

                <Stack direction="row" justifyContent="start" alignItems="center" sx={{ mt: 1 }}>
                    <FormGroup>
                        <StyledCheckboxLabel label={"Default"} control={
                            <Checkbox
                                color="primary"
                                checked={isDefault}
                                onChange={() => setIsDefault(!isDefault)} />
                        } />
                    </FormGroup>
                </Stack>
            </TableFilterContainer>

            <br />
            <Grid sx={{ alignItems: "center" }} justifyContent="right" container spacing={0}>
                <SearchButtonContainer item >
                    <BaseButton
                        label={'Save'}
                        onClick={() => { updateEndpoint()}}
                    />
                </SearchButtonContainer>
            </Grid>
        </>
    );
}

