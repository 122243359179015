import PortalLayout from 'src/layouts/PortalLayout';
import { useTranslation } from 'react-i18next';
import NumberHistory from './didHistory/NumberHistory';

// ----------------------------------------------------------------------


export default function NumberHistorySidebar() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: "Numbers History", component: <NumberHistory /> },
    ];

    const TITLE =  "Numbers History";

    return (
        <PortalLayout tabs={TABS} title={TITLE} />
    );
}
