import React from 'react';
import {
    List,
    Box, Grid, FormControl, TextField,
    IconButton,
    InputAdornment
}
    from '@mui/material';
import { useTranslation } from 'react-i18next';
import {AccountService} from 'src/api/services';
import { useState } from 'react';
import BaseSnackbar from 'src/components/BaseSnackbar';
import BaseButton from "../../../components/buttons/BaseButton";
import {styled} from "@mui/system";
import {useStore} from "../../../store/Store";
import { useFormik, Form, FormikProvider } from 'formik';
import { LoadingButton } from '@mui/lab';
import {Icon} from "@iconify/react";
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import * as Yup from 'yup';
import { ProfileService } from 'src/api/services';

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

export default function ChangePassword() {

    const { t } = useTranslation();
    const [store, dispatch] = useStore();
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");

    /* const [currentPassword, setcurrentPassword] = useState("");
    const [newPassword, setnewPassword] = useState("");
    const [repeatedPassword, setrepeatedPassword] = useState(""); */
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showVerifyPassword, setShowVerifyPassword] = useState(false);

    const ChangePasswordSchema = Yup.object().shape({
        oldPassword: Yup.string().required(t('current-password-is-required')),
        newPassword: Yup.string()
            .required(t('new-password-is-required'))
            .min(8, t('password-must-contain-at-least-8-characters')),
        newPasswordVerify: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], t('passwords-must-match'))
            .required(t('verify-password-is-required'))
    });
    


    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            newPasswordVerify: '',
        },
        validationSchema: ChangePasswordSchema,
        onSubmit: (values, actions) => {
            const payload = {
                old_password: values.oldPassword,
                new_password: values.newPassword,
                verify_password: values.newPasswordVerify,
            };
            ProfileService.changePassword(payload)
                .then((response) => {
                    if (response.data.meta.code === 200) {
                        dispatch({
                            type: "CHANGE_PASSWORD",
                            payload: {
                                token: response.data.data.access_token,
                            }
                        });
                        setMessage(t('password-has-been-successfully-changed'));
                    } else if (response.response.data.non_field_errors) {
                        setMessage(response.response.data.non_field_errors[0]);
                    } else {
                        throw "password could not be changed";
                    }
                })
                .catch((err) => {
                    setMessage(t('password-could-not-be-changed'));
                })
                .finally(() => {
                    setSnackbarStatus(true);
                    actions.setSubmitting(false);
                })
        }
    });



    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

    const handleShowCurrentPassword = () => {
        setShowCurrentPassword((showCurrentPassword) => !showCurrentPassword);
    };

    const handleShowNewPassword = () => {
        setShowNewPassword((showNewPassword) => !showNewPassword);
    };

    const handleShowVerifyPassword = () => {
        setShowVerifyPassword((showVerifyPassword) => !showVerifyPassword);
    };


    return(
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus}/>
            <Box sx= {{
                backgroundColor : "white",
                border:'2em solid white',
                borderRadius: 2,
                boxShadow: 12,
                margin: "auto",
                maxWidth: 800
            }}>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <List component="nav" aria-label="mailbox folders" >
                        <h1>Change Password</h1>
                        <br/>

                    </List>

                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                        <TextField
                                sx={{mb:3}}
                                required
                                color="secondary"
                                type={showCurrentPassword ? 'text' : 'password'}
                                label={t('current-password')}
                                {...getFieldProps('oldPassword')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleShowCurrentPassword} edge="end">
                                                <Icon icon={showCurrentPassword ? eyeFill : eyeOffFill} />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                error={Boolean(touched.oldPassword && errors.oldPassword)}
                                helperText={touched.oldPassword && errors.oldPassword}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                        <TextField
                                sx={{mb:3}}
                                required
                                color="secondary"
                                type={showNewPassword ? 'text' : 'password'}
                                label={t('new-password')}
                                {...getFieldProps('newPassword')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleShowNewPassword} edge="end">
                                                <Icon icon={showNewPassword ? eyeFill : eyeOffFill} />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                error={Boolean(touched.newPassword && errors.newPassword)}
                                helperText={touched.newPassword && errors.newPassword}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                        <TextField
                                sx={{mb:5}}
                                required
                                color="secondary"
                                type={showVerifyPassword ? 'text' : 'password'}
                                label={t('verify-password')}
                                {...getFieldProps('newPasswordVerify')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleShowVerifyPassword} edge="end">
                                                <Icon icon={showVerifyPassword ? eyeFill : eyeOffFill} />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                error={Boolean(touched.newPasswordVerify && errors.newPasswordVerify)}
                                helperText={touched.newPasswordVerify && errors.newPasswordVerify}
                            />
                        </FormControl>
                    </Grid>
                    <br />
                    <Grid sx={{ alignItems: "center" }} justifyContent="right" container spacing={0}>
                        <LoadingButton
                            color="primary"
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                        >
                            {t('change-password')}
                        </LoadingButton>
                    </Grid>
                    </Form>
                </FormikProvider >
            </Box>
        </>
    )
}
