// ----------------------------------------------------------------------
import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import {
    Grid,
    TextField,
    FormControl,
    Stack,
    FormGroup,
    Checkbox,
    FormControlLabel
} from '@mui/material';
import { TableFilterContainer } from 'src/components/table';
import {NumberService} from 'src/api/services';
import { useTranslation } from 'react-i18next';
import BaseButton from "../../../../components/buttons/BaseButton";
import {makeStyles, useTheme} from '@mui/styles';
import BaseTextField from 'src/components/BaseTextField';

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

const useStyles = makeStyles((theme) => ({
    divider: {
        borderTop: `1px solid ${theme.palette.divider}`,
    },
}));

const StyledCheckboxLabel = styled(FormControlLabel)(({ theme }) => ({
    color: theme.palette['--color-search']
}));

export default function AddSIPTrunkForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback, close }) {
    const { t } = useTranslation();


    
    const [openOrderNumberModal, setOrderNumberModalStatus] = useState(false);
    const [data, setData] = useState([]);

    const [inbound, setInbound] = useState(false);
    const [outbound, setOutbound] = useState(false);

    const [port, setPort] = useState("5060");
    const [name, setName] = useState("");
    const [priority, setPriority] = useState("1");
    const [host, setHost] = useState("");
    const [weight, setWeight] = useState("1");
    const classes = useStyles();

    const updateEndpoint = () => {

        const portNum = parseInt(port, 10);
        const priorityNum = parseInt(priority, 10);
        const weightNum = parseInt(weight, 10);
        
        if (isNaN(portNum) || isNaN(priorityNum) || isNaN(weightNum)) {
            setMessage("Port, Priority and Weight must be numbers");
            setSnackbarStatus(true);
            return;
        }

        const payload = {
            name:name,
            priority:priority, 
            weight:weight, 
            hostname:host,
            inbound: inbound,
            outbound: outbound,
            port:port
            
        };

        NumberService.addVoiceEndpoint(payload)
            .then((response) => {
                if (response.data.status == true) {
                    setMessage("Voice endpoint added");
                    setSnackbarStatus(true);
                    setModalStatus(false);
                    successCallback();
                    close();
                } else {
                    throw response.data.meta.msg;
                }
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
                setModalStatus(false);
            })

    }

    useEffect(() => {

    }, []);

    const theme = useTheme();

    return (
        <>
            <TableFilterContainer>
                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <BaseTextField
                            value={name}
                            label={t('name')}
                            name="name"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            onChange={setName}
                        />
                    </FormControl>
                </Grid>
                <br/>

                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <BaseTextField
                            value={host}
                            label={"Host Address"}
                            name="address"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            onChange={setHost}
                        />
                    </FormControl>
                </Grid>

                <br/>


                <Grid container spacing={2}>
                    <Grid item xs={6}>

                        <div>Inbound</div>
                <br/>

                        <FormControl fullWidth>
                            <BaseTextField
                                value={port}
                                label={"IB Port"}
                                name="priority"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={setPort}
                            />
                        </FormControl>
                <br/>
                <br/>

                            <FormControl fullWidth>
                                <BaseTextField
                                    value={priority}
                                    label={"IB Priority"}
                                    name="priority"
                                    margin="normal"
                                    variant="outlined"
                                    color="secondary"
                                    onChange={setPriority}
                                />
                            </FormControl>
                            <br/>
                            <br/>

                            <FormControl fullWidth>
                                <BaseTextField
                                    value={weight}
                                    label={"IB Weight"}
                                    name="weight"
                                    margin="normal"
                                    variant="outlined"
                                    color="secondary"
                                    onChange={setWeight}
                                />
                            </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <div>Outbound</div>
                        <br/>

                        <FormControl fullWidth>
                            <BaseTextField
                                value={"5060"}
                                label={"OB Port"}
                                name="priority"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={event => { setPriority(event.target.value) }}
                                disabled={true}
                            />
                        </FormControl>

                        <br/>
                        <br/>

                            <FormControl fullWidth>
                                <BaseTextField
                                    value={"1"}
                                    label={"OB Priority"}
                                    name="priority"
                                    margin="normal"
                                    variant="outlined"
                                    color="secondary"
                                    onChange={event => { setPriority(event.target.value) }}
                                    disabled={true}

                                />
                            </FormControl>
                            <br/>
                            <br/>

                            <FormControl fullWidth>
                                <BaseTextField
                                    value={"1"}
                                    label={"OB Weight"}
                                    name="weight"
                                    margin="normal"
                                    variant="outlined"
                                    color="secondary"
                                    onChange={event => { setWeight(event.target.value) }}
                                    disabled={true}

                                />
                            </FormControl>
                    </Grid>

                </Grid>

                <Stack direction="row" justifyContent="start" alignItems="center" sx={{ mt: 1 }}>
                    <FormGroup>
                        <StyledCheckboxLabel label={"Inbound Enabled"} control={
                            <Checkbox
                                color="primary"
                                checked={inbound}
                                onChange={() => setInbound(!inbound)} />
                        } />
                      
                    </FormGroup>

                    <FormGroup>
                        <StyledCheckboxLabel label={"Outbound Enabled"} control={
                            <Checkbox
                                color="primary"
                                checked={outbound}
                                onChange={() => setOutbound(!outbound)} />
                        } />
                      
                    </FormGroup>
                </Stack>

            </TableFilterContainer>

            <br />
            <Grid sx={{ alignItems: "center" }} justifyContent="right" container spacing={0}>
                <SearchButtonContainer item >
                    <BaseButton
                        label={'Save'}
                        onClick={() => updateEndpoint()} color={'--color-primary'}
                    />
                </SearchButtonContainer>
            </Grid>
        </>
    );
}

