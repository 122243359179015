import {NavLink} from 'react-router-dom';
// material
import {styled} from '@mui/material/styles';
import {Container} from '@mui/material';
// components
import Page from '../components/Page';
import {LoginForm} from '../components/authentication/login';
import {AdminLoginForm} from '../components/authentication/login';
import {RegisterForm} from '../components/authentication/register';
import { useTranslation } from 'react-i18next';
import {useStore} from "../store/Store";
import {ForgotPassword} from "../components/authentication/forgot-password";
import {useEffect, useState} from "react";
import {AcceptInvitation} from "../components/authentication/accept-invitation";
import TwoFactorAuthForm from 'src/components/authentication/login/TFAForm';
import AddPhoneForm from './app/profile/ForceAddPhone';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme, backgroundimage }) => ({
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    minHeight: '100vh',
    backgroundImage: `url(${backgroundimage})`, // Use template literals and string interpolation here
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
}));

const ContentStyle = styled('div')(({ theme }) => ({
    textAlign: 'start',
}));


// ----------------------------------------------------------------------

export default function Auth({type = "login"}) {

    const { t } = useTranslation();
    let title, subtitle, link, navigateText, formComponent, navLink;

    const [backgroundImage, setBackgroundImage] = useState("");
    const [store, dispatch] = useStore();

    if (type === "login") {
        title = t('login');
        subtitle = t('sign-in');
        link = "/register";
        navigateText = t('register-if-you-dont-have-an-account');
        formComponent = <LoginForm/>;
    } else if (type === "admin-login") {
        title = t('admin-login');
        subtitle = t('sign-in-administration');
        link = "/admin/login";
        navigateText = t('please-contact-with-support-team');
        formComponent = <AdminLoginForm/>;
    } else if (type === "register") {
        title = t('Register');
        subtitle = t('register-0');
        link = "/login";
        navigateText = t('login-if-you-already-have-an-account');
        formComponent = <RegisterForm/>;
    } else if (type === "reset-password") {
        title = t('Forgot Password');
        subtitle = t('register-0');
        link = "/reset-password";
        navigateText = t('login-if-you-already-have-an-account');
        formComponent = <ForgotPassword/>;
    } else if (type === "accept-invitation") {
        title = t('Accept Invitation');
        subtitle = t('accept-invitation');
        navigateText = t('accept-invitation');
        formComponent = <AcceptInvitation/>;
    } else if (type === "reset-password-email") {
        title = t('Set New Password');
        subtitle = t('accept-invitation');
        navigateText = t('accept-invitation');
        formComponent = <AcceptInvitation/>;
    } else if (type === "2fa") {
        title = t('Two Factor Authentication');
        subtitle = t('Two Factor Authentication');
        navigateText = t('Two Factor Authentication');
        formComponent = <TwoFactorAuthForm/>;
    } else if (type === "force-add-phone") {
        title = t('Add Phone');
        subtitle = t('Add Phone');
        navigateText = t('Add Phone');
        formComponent = <AddPhoneForm/>;
    }


    useEffect(() => {
        if (store.backgroundImage) {
            setBackgroundImage("data:image/png;base64,"+store.backgroundImage);
        }
    }, [store.manualUpdateTriggeredForPalette, store.backgroundImage]);

    return (
        <RootStyle backgroundimage={backgroundImage} title={`${title}`}>
            <Container maxWidth="sm">
                <ContentStyle>

                    {formComponent}
                    {/*navLink*/}
                </ContentStyle>
            </Container>
        </RootStyle>
    );
}
