import { useState } from 'react';
// material
import {
    Stack,
    Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// app
import { NumberService } from 'src/api/services';
import { useTranslation } from 'react-i18next';
import BaseButton from 'src/components/buttons/BaseButton';
// ----------------------------------------------------------------------

export default function DeleteEndpointForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const deallocateNumber = () => {
        setLoading(true);
        NumberService.deleteVoiceEndpoint(formData.id)
            .then((response) => {
                if (response.data.meta.code === 200) {
                    setMessage("Voice endpoint deleted");
                    setSnackbarStatus(true);
                    setModalStatus(false);
                    setLoading(false);
                    successCallback();
                } else {
                    throw "endpoint could not be deleted";
                }
            })
            .catch((err) => {
                setMessage('endpoint could not be deleted');
                setSnackbarStatus(true);
                setModalStatus(false);
                setLoading(false);
            });
    }

    return (
        <>
            <Stack spacing={3}>
                <Typography component="h6">
                {"You are about to remove the interconnection"}
                </Typography>

                <Stack sx={{ display: "block" }} direction="row" spacing={2}>
                    <BaseButton
                        type="submit"
                        color={'--color-search'}
                        variant="contained"
                        disabled={loading}
                        onClick={() => setModalStatus(false)}
                    >
                        {t('cancel')}
                    </BaseButton>
                    <LoadingButton
                        type="submit"
                        color="primary"
                        variant="contained"
                        loading={loading}
                        onClick={deallocateNumber}
                    >
                        {t('delete')}
                    </LoadingButton>
                </Stack>
            </Stack>
        </>
    );
}
